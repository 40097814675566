<template>
	<div>
		<v-card>
			<v-card-title> Relatório de Eventos </v-card-title>
			<v-card-text>
				<!-- linha do filtro -->
				<v-card outlined>
					<v-card-text class="pb-0">
						<p class="text-subtitle-1 pt-1 pb-3">Filtros</p>
						<v-form ref="formReport">
							<v-row>
								<v-col class="py-0">
									<v-text-field v-model="relatorio.start" label="Data Inicial" required outlined type="datetime-local"
										@input="$refs.relatorioEnd.validate()"></v-text-field>
								</v-col>
								<v-col class="py-0">
									<v-text-field ref="relatorioEnd" v-model="relatorio.end" label="Data Final" required outlined
										type="datetime-local" :rules="[
											(v) =>
												validaDataFinal(v) ||
												'A data final deve ser maior que a inicial',
										]"></v-text-field>
								</v-col>
							</v-row>
							<v-row>
								<v-col class="py-0">
									<v-autocomplete v-model="relatorio.eventType" :items="eventTypes" item-text="name" item-value="code"
										label="Tipo de Evento" clearable multiple small-chips deletable-chips outlined>
									</v-autocomplete>
								</v-col>
								<v-col class="py-0">
									<v-autocomplete v-model="clientId" label="Cliente" hint="Selecione um cliente para filtrar"
										:items="getClienteSelect" persistent-hint outlined clearable>
									</v-autocomplete>
								</v-col>
								
							</v-row>
							<v-row>
								<v-spacer></v-spacer>
								<v-col class="py-0" cols="auto">
									<v-switch v-model="relatorio.showNulls" class="d-inline-block" label="Mostrar não finalizados" />
								</v-col>
							</v-row>
						</v-form>
					</v-card-text>
					<v-card-actions class="px-4">
						<MenuExportReport :disabled="!getRelEvents.length" :items="getRelEvents" module="eventos"
							title="Relatório de Eventos" filename="eventos" :optionImg="false" />
						<v-spacer></v-spacer>
						<v-btn color="green darken-1" text @click="loadReport">
							<v-icon class="mr-1">mdi-magnify</v-icon> Buscar Relatório
						</v-btn>
					</v-card-actions>
				</v-card>
				<!-- linha da tabela -->
			</v-card-text>
			<TableEventos :headers="headers" :items="getRelEvents" :report="true" />
		</v-card>
	</div>
</template>

<script>
import { mapGetters } from "vuex";
import MenuExportReport from "@/components/Relatorios/MenuExportReport.vue";
import TableEventos from './TableEventos.vue';
import { differenceDate } from "@/Utils/DateDiff";

export default {
	name: "RelatorioEventos",
	components: {
		MenuExportReport,
		TableEventos,
	},
	computed: {
		...mapGetters([
			"getRelEvents",
			"getUserNameById",
			"getClienteNameById",
			"getAmbienteNameById",
			"getClienteSelect",
		]),
	},
	data() {
		return {
			ocorrencia: {},
			headers: [
				{ text: "ID", value: "id" },
				{ text: "Evento", value: "eventName" },
				{ text: "Cliente", value: "clientId" },
				{ text: "Origem", value: "createdUserId" },
				{ text: "Dispositivo", value: "deviceId" },
				{ text: "Responsável", value: "finishedUserId" },
				{ text: "Data", value: "createdAt" },
				{ text: "Atendimento", value: "startedAt" },
				{ text: "Finalização", value: "finishedAt" },
				{ text: "Resolução", value: "treatment" },
			],
			relatorio: {},
			eventTypes: [],
			clientId: 0,
		};
	},
	methods: {
		loadReport() {
			if (this.$refs.formReport.validate()) {
				delete this.relatorio.clientId;
				if(this.clientId){
					this.relatorio.clientId = this.clientId;
				}
				this.$store.dispatch("loadReportEvents", this.relatorio);
			}
		},
		getDia(data) {
			if (data) {
				let dataFormatada = new Date(data);
				return dataFormatada.toLocaleDateString();
			}
		},
		verificaUsuarios(id) {
			if (id == null || id == 0) {
				return "Gerado automaticamente";
			} else {
				return this.getUserNameById(id);
			}
		},
		calculaIntervalo(item) {
			let intervalo = "";
			let now = new Date();
			if (item.finishedAt) {
				now = item.finishedAt;
			}
			intervalo = differenceDate(
				item.createdAt, now
			);
			let format = `${intervalo.minute} minutos`
			if (intervalo.hour > 0) {
				format = `${intervalo.hour} horas e ${intervalo.minute} minutos`
			}
			if (intervalo.day > 0) {
				format = `${intervalo.day} dias, ${intervalo.hour} horas e ${intervalo.minute} minutos`
			}
			return format;
		},
		validaDataFinal(dataFinal) {
			// função auxiliar pois este teste direto no rules não funciona corretamente
			return new Date(dataFinal) > new Date(this.relatorio.start);
		},
	},
	created() {
		this.$store.dispatch("setRelEvents", []);
		this.$store.dispatch("loadEvents", this.$store.getters.getCliente.clientId || null);
		this.$store.dispatch("loadDispositivos",);
		this.$store.dispatch(
			"loadClientes",
			this.$store.getters.getCliente.groupId || null
		);
		this.$http("eventType").then((resp) => {
			this.eventTypes = resp.data;
		});
		this.$store.dispatch("loadUsers");
		// pega a data atual e seta no relatorio
		let now = new Date();
		let onlyDate = now.toISOString().substring(0, 11);
		this.relatorio.start = onlyDate + "00:00";
		this.relatorio.end = onlyDate + "23:59";
	},
};
</script>

<style></style>